import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailIcon from '../images/icons/email.svg';
import phoneIcon from '../images/icons/phone.svg';
import markerIcon from '../images/icons/marker.svg';
import mainLogo from '../images/logo_white_new.png';
import '../styles/components/Header.css';

const Header = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [isHomePage, setIsHomePage] = useState(false);

    const [dynamicText, setDynamicText] = useState('');
    const messages = [
        "Data Science, Big Data, Analytics, R Programming, Hadoop... you get it!",
        "Oracle Enterprise - Database, BI, J2EE ... the whole stack!",
        "SAP Retail, eWM & HANA Consulting.. We are focused!"
    ];
    let messageIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    const typingSpeed = 50;
    const deletingSpeed = 50;
    const delayBetweenMessages = 5000; 

    const type = () => {
        const currentMessage = messages[messageIndex];
        if (isDeleting) {
            setDynamicText(currentMessage.substring(0, charIndex--));
            if (charIndex < 0) {
                isDeleting = false;
                messageIndex = (messageIndex + 1) % messages.length;
                setTimeout(type, 500);
            } else {
                setTimeout(type, deletingSpeed);
            }
        } else {
            setDynamicText(currentMessage.substring(0, charIndex++));
            if (charIndex > currentMessage.length) {
                isDeleting = true;
                setTimeout(type, delayBetweenMessages);
            } else {
                setTimeout(type, typingSpeed);
            }
        }
    };

    useEffect(() => {
        type();
    }, []);

    useEffect(() => {
        const pathname = window.location.pathname;
        setIsHomePage(pathname === '/');
    }, []);

    const openNav = () => {
        setMobileNavOpen(true);
    };

    const closeNav = () => {
        setMobileNavOpen(false);
    };

    //<img className="logo" src={mainLogo} alt="Asskmind Logo" />
    return (
        <header id="header" style={{ height: !isHomePage ? '220px' : '' }}>
            <Container>
                <div className="fixed_header" id="fixedHeader">
                    <div className="topbar">
                        <Container>
                            <ul>
                                <li><a href="mailto:info@muktsoftware.com"><img src={emailIcon} alt="Email Icon" /> hr@asskmind.com</a></li>
                                <li><a href="https://wa.me/18204440552" target="_blank" rel="noopener noreferrer"><img src={phoneIcon} alt="Phone Icon" /> +1 (820) 444 0552</a></li>
                                <li><a href="https://www.google.com/maps/place/South Elgin, IL" target="_blank" rel="noopener noreferrer"><img src={markerIcon} alt="Marker Icon" /> South Elgin, IL</a></li>
                            </ul>
                        </Container>
                    </div>
                    <Container>
                        <div className="navbar">
                            <img className="logo" src={mainLogo} alt="Asskmind Logo" />
                            <div className="buttons">
                                <a href="/">Home</a>
                                <a href="/about">About us</a>
                                <a href="/clients">Clients</a>
                                <a href="/career">Career</a>
                                <a href="/contact">Contact</a>
                            </div>
                            <div className="mobileButtons">
                                <a onClick={openNav}><i className="fa fa-bars"></i></a>
                                {mobileNavOpen && (
                                    <div id="mobileNavbar" className="overlay" style={{ display: mobileNavOpen ? 'block' : 'none' }}>
                                        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}><i className="fa fa-close"></i></a>
                                        <div className="overlay-content">
                                            <a href="/">Home</a>
                                            <a href="/about">About us</a>
                                            <a href="/clients">Clients</a>
                                            <a href="/career">Career</a>
                                            <a href="/contact">Contact</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
                {isHomePage && (
                    <div className="bannerText">
                        <Row style={{ marginTop: '120px' }}>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <h2 id="dynamic_messages">{dynamicText}</h2>
                                <p>In a Data crazed world, Asskmind offers speciality consulting with data and the science surrounding it.</p>
                                <div className="buttons">
                                    <button onClick={() => window.location.href = '/contact'}>Contact Us</button>
                                    <a href="/about">Read more &gt;</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Container>
        </header>
    );
};

export default Header;
